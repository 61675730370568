/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

export default function Desc() {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className="w-full lg:w-9/12 px-4">
        <p
          className={`mb-4 text-lg leading-relaxed text-gray-800 ${
            showMore ? "block" : "hidden"
          }`}
        >
          Seasoned Full Stack Developer with a comprehensive background in
          creating robust web applications. With nearly 2 years of hands-on
          experience, I specialize in Vue.js for frontend development,
          complemented by proficiency in Kotlin Springboot and PHP Laravel for
          backend solutions. My journey commenced at the University of
          Indonesia, where I majored in Computer Engineering, fostering a solid
          technological foundation. Building upon this, I further honed my
          skills as a Full Stack Developer at Investree, where I contributed to
          innovative projects, refined my problem-solving abilities, and
          enhanced my collaborative skills in a dynamic team environment.
          Passionate about delivering user-centric solutions, I'm enthusiastic
          about exploring new opportunities to apply my expertise and drive
          impactful projects in the tech industry.
        </p>
        <p
          className={`mb-4 text-lg leading-relaxed text-gray-800 ${
            showMore ? "hidden" : "block"
          }`}
        >
          Seasoned Full Stack Developer with a comprehensive background in
          creating robust web applications. With nearly 2 years of hands-on
          experience, I specialize in Vue.js for frontend development,
          complemented by proficiency in Kotlin Springboot and PHP Laravel for
          backend solutions.
        </p>
        <a
          className="font-normal text-pink-500"
          onClick={handleShowMore}
        >
          {showMore ? "Show less" : "Show more"}
        </a>
      </div>
    </>
  );
}
